var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"category-item d-flex flex-column h-100"},[_c('v-img',{attrs:{"src":_vm.image,"height":"180","max-height":"180"}}),_c('v-card-title',{staticClass:"flex-grow-1"},[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.category.name)+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"bottom":"","left":"","close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"width":"150"}},[_c('CategoryDialog',{attrs:{"category":_vm.category,"buttonText":_vm.$t('buttons.edit'),"buttonIcon":"mdi-pencil-outline","buttonAttrs":{
                class: 'btn-justify-left',
                block: true,
                color: 'transparent',
              }},on:{"click":function($event){_vm.menu = false}}}),_c('confirm-dialog',{attrs:{"button-text":_vm.$t('buttons.delete'),"button-icon":"mdi-trash-can-outline","button-attrs":{
                class: 'btn-justify-left',
                block: true,
                color: 'transparent',
                loading:
                  _vm.$store.getters['movieGenres/loading'].delete == _vm.category.id,
              }},on:{"confirm":_vm.deleteCategory}})],1)],1)],1)],1)],1),_c('v-card-text',[_c('div',[_vm._v(" "+_vm._s(_vm.$t("labels.products"))+": "),_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.category.product_count))])]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("labels.hits"))+": "),_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.category.number_of_clicks))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }